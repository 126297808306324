<template>
  <div class="container">
    <div style="text-align:right" class="mt-3">

      <!-- @click="ExportExcel('xlsx', 'รายงานการเข้าพัก.xlsx')" -->
      <button
      @click="exportTableToExcel('tblData')"
        class="btn btn-success"
      >
        <i class="fa fa-file-excel-o"></i> Export
      </button></div>
      <div style="text-align:right" class="mt-3">
        <a>
          <button
            type="button"
            class="btn btn-success btn-md"
            data-bs-toggle="modal"
            data-bs-target="#AddUser"
            @click="getlisthome()"
          >
            <i class="fa fa-plus"></i>
          </button>
          </a></div>
    <table class="table mt-3">
      <tr>
        <th :colspan="parseInt(datelist.length)+3" style="text-align: center">
          บ้านชลธาร เขาใหญ่
        </th>
      </tr>
    
      <tr>
        <th style="text-align: center" :colspan="parseInt(datelist.length)+3">บ้านและคอนโดที่เข้าพัก และลูกค้าเข้ามาดูบ้านและคอนโดวันที่ {{ fulldate }}</th>
      </tr>
      <tr>
            <th style="vertical-align: middle;text-align: center;width:200px" rowspan="2">บ้านและคอนโด</th>
            <th style="text-align: center" :colspan="datelist.length">วันที่</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2">ผู้เข้าพัก</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2"></th>
        </tr>
        <tr>
            <td v-for="d in datelist" :key="d.id">{{d}}</td>
        </tr>
        <tr v-for="l in list" :key="l.id">
            <td>{{l.name}}</td>
           
            <td v-for="v in l.detail" :key="v.id"> 
              <input type="checkbox" @change="updatename(v)" style="margin-left: 0px;" 
              class="form-check-input" id="flexCheckChecked" value="true" v-model="v.value">
          </td>
          <td>{{l.ownname}}</td>
          <td> <a><button
              @click="deletework(l.nameId)"
              type="button"
              class="btn btn-danger btn-md"
            >
              <i class="fa fa-remove"></i>
            </button></a></td>
        </tr>
        <tr>
            <td :colspan="parseInt(datelist.length)+2"></td>
        </tr>
        <tr>
            <td :colspan="parseInt(datelist.length)+2"><input
              v-model="detail.home"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td :colspan="parseInt(datelist.length)+2"><input
              v-model="detail.condo"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
    </table>
    <div id="dvData">
    <table class="table mt-3" border="1" style="border-collapse: collapse;display: none"
    id="tblData"
      ref="exportable_table"
     >
      <tr>
        <th :colspan="parseInt(datelist.length)+2" style="text-align: center;font-size:18px">
          บ้านชลธาร เขาใหญ่
        </th>
      </tr>
    
      <tr>
        <th style="text-align: center;font-size:14px" :colspan="parseInt(datelist.length)+2">บ้านและคอนโดที่เข้าพัก และลูกค้าเข้ามาดูบ้านและคอนโดวันที่ {{ fulldate }}</th>
      </tr>
      <tr>
            <th style="vertical-align: middle;text-align: center;width:200px;font-size:14px" rowspan="2">บ้านและคอนโด</th>
            <th style="text-align: center;font-size:14px" :colspan="datelist.length">วันที่</th>
            <th style="vertical-align: middle;text-align: center;font-size:14px" rowspan="2">ผู้เข้าพัก</th>
        </tr>
        <tr>
            <td style="font-size:14px" v-for="d in datelist" :key="d.id">{{d}}</td>
        </tr>
        <tr v-for="l in list" :key="l.id">
            <td style="font-size:14px">{{l.name}}</td>
           
            <td v-for="v in l.detail" :key="v.id"> 
             <span v-if="v.value==1" style="font-size:14px">1</span>
             <span v-else style="font-size:14px"></span>
          </td>
          <td style="font-size:14px">{{l.ownname}}</td>
          
        </tr>
        <tr>
            <td style="font-size:14px" :colspan="parseInt(datelist.length)+2"></td>
        </tr>
        <tr>
            <td style="font-size:14px" :colspan="parseInt(datelist.length)+2">{{detail.home}}</td>
        </tr>
        <tr>
            <td style="font-size:14px" :colspan="parseInt(datelist.length)+2">{{detail.condo}}</td>
        </tr>
    </table>
    </div>
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">เพิ่มบ้านหรือคอนโด</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="username">บ้านหรือคอนโด</label>
                <select
            v-model="data.nameId"
            class="form-control form-control-sm"
            id="exampleFormControlSelect1"
          >
            <option v-for="(s, i) in typelist" v-bind:value="s.id" :key="i + 1">
              {{ s.name }}
            </option>
          </select>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="save()"
            >
              Save
            </button>
            <button
              id="closedmenu"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx-js-style';
import WorkReportService from "../services/WorkReportService.js";
import CheckInService from "../services/CheckInService";


export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
      jsondata: [
        {
          date: "2024-06-01",
          name: "นาย ประไพ",
          work: "รดน้ำ บ้านแปลง 25 กวาดใบไม้แปลง 25,29",
          remark: "",
        },
        { user: "", name: "", work: "", remark: "" },
      ],
      list: [],
      start: "",
      end: "",
      fulldate: "",
      date: "",
      month: "",
      year: "",
      data: {},
      datelist:[],
      count:0,
      typelist:[],
      detail:{
        home:'ที่พักประจำมี บ้านหลัง 27,31,33 (หลัง 27 คุณเล็ก หลัง 33 คุณครีม หลัง 31 ปล่อยเช่ารายเดือนบ้านคุณต้อม)',
        condo:'คอนโดมีพักประจำ ห้อง 311 (เพื่อนเจ้าของห้อง) ห้อง 312 (เพื่อนเจ้าของห้อง)'
      }
    };
  },
  mounted() {
    this.datelist = []
    this.date = this.$route.params.date;
    this.month = parseInt(this.$route.params.month) - 1;
    this.year = this.$route.params.year;
    this.date = ("0" + this.date).slice(-2);
    this.month = ("0" + this.month).slice(-2);

    var date = new Date(this.year, this.month, this.date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log(this.year,this.month,this.date);
    if (date.getDate() <= 15) {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-01";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-15";

      this.fulldate += "1 - 15 ";
      for (let i = 0; i < 15; i++) {
            this.datelist.push(i+1)
          }
    } else {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-16";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2);
      // var l = ((lastDay.getDate()).slice(-2))
      // console.log(l);
      this.fulldate += "16 - " + lastDay.getDate() + " ";
      for (let i = 15; i < parseInt(lastDay.getDate()); i++) {
            
        this.datelist.push(i+1)
          }
    }

    const result = date.toLocaleDateString("th-TH", {
      month: "long",
    });
    this.fulldate += result + " " + (parseInt(date.getFullYear()) + 543);
    this.getlist()
    this.getlisthome()
  },
  methods: {
    exportTableToExcel() {
      const table = document.querySelector('#dvData table');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([]);
  var nullrow = this.list.length + 6
  var one = this.list.length + 7
  var two = this.list.length + 8
  console.log(this.datelist.length);
  if (this.datelist.length == 13) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:O2"),
  XLSX.utils.decode_range("A3:O3"),
  XLSX.utils.decode_range("A4:A5"),
  XLSX.utils.decode_range("B4:N4"),
  XLSX.utils.decode_range("O4:O5"),
  XLSX.utils.decode_range("A"+nullrow+":O"+nullrow),
  XLSX.utils.decode_range("A"+one+":O"+one),
  XLSX.utils.decode_range("A"+two+":O"+two),
]
}
else if (this.datelist.length == 14) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:P2"),
  XLSX.utils.decode_range("A3:P3"),
  XLSX.utils.decode_range("A4:A5"),
  XLSX.utils.decode_range("B4:O4"),
  XLSX.utils.decode_range("P4:P5"),
  XLSX.utils.decode_range("A"+nullrow+":P"+nullrow),
  XLSX.utils.decode_range("A"+one+":P"+one),
  XLSX.utils.decode_range("A"+two+":P"+two),
]
}
else if (this.datelist.length == 15) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:Q2"),
  XLSX.utils.decode_range("A3:Q3"),
  XLSX.utils.decode_range("A4:A5"),
  XLSX.utils.decode_range("B4:P4"),
  XLSX.utils.decode_range("Q4:Q5"),
  XLSX.utils.decode_range("A"+nullrow+":Q"+nullrow),
  XLSX.utils.decode_range("A"+one+":Q"+one),
  XLSX.utils.decode_range("A"+two+":Q"+two),
]
}
else if (this.datelist.length == 16) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:R2"),
  XLSX.utils.decode_range("A3:R3"),
  XLSX.utils.decode_range("A4:A5"),
  XLSX.utils.decode_range("B4:Q4"),
  XLSX.utils.decode_range("R4:R5"),
  XLSX.utils.decode_range("A"+nullrow+":R"+nullrow),
  XLSX.utils.decode_range("A"+one+":R"+one),
  XLSX.utils.decode_range("A"+two+":R"+two),
]
}
  const rows = table.rows;
  // console.log(rows);
  for (let i = 0; i < rows.length; i++) {
    var rowData = [];
    const cells = rows[i].cells;
    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      // attention here
      const nestedTable = cell.querySelector('table');
      if (nestedTable) {
        const nestedRows = nestedTable.rows;
        for (let k = 0; k < nestedRows.length; k++) {
          const nestedCells = nestedRows[k].cells;
          const nestedRowData = [];
          for (let l = 0; l < nestedCells.length; l++) {
            const nestedCell = nestedCells[l];
            nestedRowData.push(nestedCell.innerText);
          }
          rowData.push(...nestedRowData);
        }
      } else {
        // console.log(1);
        rowData.push(cell.innerText);
      }
    } 
    // var mergename = []
    if (this.datelist.length == 13) {
      if (rowData.length == 1) {
      var counts = parseInt(this.datelist.length)+1
          for (let i = 0; i < counts; i++) {
            rowData.push('')
          }
        }
        if (rowData.length == 3) {
          var originalArray = rowData;
          for (let ao = 0; ao < (this.datelist.length-1); ao++) {
originalArray = this.insertItemAtIndex(originalArray, ao+2, '');
// console.log(originalArray);            
          }
          rowData = originalArray
        }
        if (rowData.length == 13) {
          rowData = this.insertItemAtIndex(rowData, 0, '');
          rowData.push('')
        }
    }else 
    if (this.datelist.length == 14) {
      if (rowData.length == 1) {
       counts = parseInt(this.datelist.length)+1
          for (let i = 0; i < counts; i++) {
            rowData.push('')
          }
        }
        if (rowData.length == 3) {
           originalArray = rowData;
          for (let ao = 0; ao < (this.datelist.length-1); ao++) {
originalArray = this.insertItemAtIndex(originalArray, ao+2, '');
// console.log(originalArray);            
          }
          rowData = originalArray
        }
        if (rowData.length == 14) {
          rowData = this.insertItemAtIndex(rowData, 0, '');
          rowData.push('')
        }
    }else{
      if (rowData.length == 1) {
       counts = parseInt(this.datelist.length)+1
          for (let i = 0; i < counts; i++) {
            rowData.push('')
          }
        }
        if (rowData.length == 3) {
           originalArray = rowData;
          for (let ao = 0; ao < (this.datelist.length-1); ao++) {
originalArray = this.insertItemAtIndex(originalArray, ao+2, '');
// console.log(originalArray);            
          }
          rowData = originalArray
        }
        if (rowData.length == 15 || rowData.length == 16) {
          rowData = this.insertItemAtIndex(rowData, 0, '');
          rowData.push('')
// console.log(originalArray);            
          
        }
    }
  console.log(rowData);
    XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    
  }
  for (var i in worksheet) {
    // console.log(i);
    if (typeof worksheet[i] != 'object') continue;
    let cell = XLSX.utils.decode_cell(i);
// console.log(cell.c);
    worksheet[i].s = {
  // styling for all cells
  font: {
    name: 'cordia new',
    sz:16
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '1', // any truthy value here
  },
  border: {
    right: {
      style: 'thin',
      color: '000000',
    },
    left: {
      style: 'thin',
      color: '000000',
    },
    top: {
      style: 'thin',
      color: '000000',
    },
    bottom: {
      style: 'thin',
      color: '000000',
    },
  }
    }
    if (cell.c == 0 && cell.r == 3) {
      worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }
    if (cell.c == 0 && cell.r > 2) {
        // first row
        worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }

      if (cell.r == 0) {
        // first row
        worksheet[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }


    if (i == 'A2' || i == 'A3' || i == 'A4' || i == 'B4' || i == 'A5' || i == 'Q4' || i == 'R4' || i == 'P4') {
      worksheet['!rows'] = [
            {'hpt' : 30},// height for row 1
            {'hpt' : 30},
          {'hpt' : 30},
          {'hpt' : 20}, ]; //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '18',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
  }

  var wscols = [
    {wch:20},
];
for (let d = 0; d < this.datelist.length; d++) {
  wscols.push({wch:5})
}
wscols.push({wch:20})
worksheet['!cols'] = wscols;
  // console.log(worksheet["A3"]);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  // console.log(workbook);
  var filename = 'รายงานการเข้าพัก_'+this.fulldate+ ".xlsx"
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:application/octet-stream;base64,${btoa(excelFile)}`;
  downloadLink.download = filename;
  downloadLink.click();

    },
    // exportTableToExcel(tableID, filename = "") {
    //   var downloadLink;
    //   var dataType = "application/vnd.ms-excel";
    //   var tableSelect = document.getElementById(tableID);
    //   var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    //   // Specify file name
    //   filename = 'รายงานการเข้าพัก_'+this.fulldate+ ".xls"
    //   // Create download link element
    //   downloadLink = document.createElement("a");

    //   document.body.appendChild(downloadLink);

    //   if (navigator.msSaveOrOpenBlob) {
    //     var blob = new Blob(["\ufeff", tableHTML], {
    //       type: dataType,
    //     });
    //     navigator.msSaveOrOpenBlob(blob, filename);
    //   } else {
    //     // Create a link to the file
    //     downloadLink.href = "data:" + dataType + ", " + tableHTML;

    //     // Setting the file name
    //     downloadLink.download = filename;

    //     //triggering the function
    //     downloadLink.click();
    //   }
    // },
    insertItemAtIndex(arr, index, item) {
  if (index < 0 || index > arr.length) {
    // Index out of bounds, 
    // return the original array
    return arr;
  }

  return arr.slice(0, index)
    .concat(item, arr.slice(index));
},
getlisthome(){
      this.typelist = []
      this.data = {}
CheckInService.getlisthome(this.start,this.end).then((res)=>{
  this.typelist = res.data
  // console.log(res.data,'home');
})
    },
    updatename(event){
      var value = event.value
      console.log(event);
      if (event.value == 0) {
        value = '0'
      }else{
        value = '1'
      }
var data = {
          value: value,
        };
        // console.log(event);
        CheckInService.updatecheckin(event.id,data).then(() => {
          this.getlist();
        });
    },
    save() {
      if (this.data.nameId == null || this.data.nameId == "") {
        alert("กรุณาเลือกบ้านหรือคอนโด");
      } else {
    var date = new Date(this.year, this.month, this.date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log(this.year,this.month,this.date);
    // console.log(result);
        if (date.getDate() <= 15) {
          for (let i = 0; i < 15; i++) {
            var data = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
              nameId: this.data.nameId,
              value:0,
              status:1
            };
            CheckInService.createcheckin(data).then(() => {
              document.getElementById("closedmenu").click();
              this.getlist();
              this.getlisthome()
            });
          }
        } else {
          for (let i = 15; i < parseInt(lastDay.getDate()); i++) {
            var datalast = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
              nameId: this.data.nameId,
              value:0,
              status:1
            };
            CheckInService.createcheckin(datalast).then(() => {
              document.getElementById("closedmenu").click();
              this.getlist();
              this.getlisthome()
            });
          }
        }
      }
  
    },
    onUnpublishedChange() {
      for (let l = 0; l < this.list.length; l++) {
        // console.log(l+1);
        var list = {
          no: l + 1,
        };
        WorkReportService.updateno(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getlist();
          }
        });
      }
    },
    deletework(id) {
      console.log(id);
      CheckInService.deletecheckin(id).then(() => {
        this.getlist();
      });
    },
    getlist() {
      this.count = 0
      CheckInService.getcheckins(this.start, this.end).then((res) => {
        console.log(res.data,'list');
        this.list = res.data
      });
    },
    savework(ev) {
      console.log(ev);
    },
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheets" });
      wb["Sheets"]["!cols"] = [{ wpx: 70 }, { wpx: 121 }];
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn || (this.name + "." || "SheetJSTableExport.") + (type || "xlsx")
          );
    },
    exportAsCsv() {
      const format = "csv";
      const exportSelectedOnly = true;
      const filename = "test";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* th{
  border: 1px solid black !important;
}
td{
  border: 1px solid black !important;
} */
table,
th,
td {
  border: 1px solid #666;
  border-collapse: collapse;
}
.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}
</style>
