<template>
  <div class="container">
    <div class="row mt-5">
      <div style="text-align:right" class="mt-3">
      <button
      @click="saveDocx()"
        class="btn btn-primary"
      >
        <i class="fa fa-file-word-o"></i> Export
      </button></div>
    
      <div class="col-md-12 mt-3">
        <vue-editor use-custom-image-handler v-model="data" />
      </div>
    </div>
  </div>
</template>

<script>
import { asBlob } from 'html-docx-js-typescript'
// if you want to save the docx file, you need import 'file-saver'
import { saveAs } from 'file-saver'
import CheckInService from "../services/CheckInService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      list: [],
      fulldate: "",
      start: "",
      end: "",
      date: "",
      month: "",
      year: "",
      datewithdraw: "",
      data: "",
      Buddhist: "",
    };
  },
  mounted() {
    this.date = this.$route.params.date;
    this.month = parseInt(this.$route.params.month) - 1;
    this.year = this.$route.params.year;
    this.date = ("0" + this.date).slice(-2);
    this.month = ("0" + this.month).slice(-2);

    var date = new Date(this.year, this.month, this.date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log(this.year,this.month,this.date);
    if (date.getDate() <= 15) {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-01";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-15";

      this.fulldate += " 15 ";
    } else {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-16";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2);
      // var l = ((lastDay.getDate()).slice(-2))
      // console.log(l);
      this.fulldate += lastDay.getDate() + " ";
    }
    // console.log(this.start);
    // console.log(this.end);

    const result = date.toLocaleDateString("th-TH", {
      month: "long",
    });
    this.fulldate += result + " " + (parseInt(date.getFullYear()) + 543);
    // console.log(this.fulldate);
    CheckInService.findBuddhist(this.start, this.end).then((res) => {
      // console.log(res.data);
      for (let r = 0; r < res.data.length; r++) {
        var dt = new Date(res.data[r].date);
        var result = dt.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        this.Buddhist += '<p style="font-size:20px;">วันพระที่ ' + result + " จำนวน 300 บาท</p>";
        if (r + 1 == res.data.length) {
          this.data =
            '<p style="font-size:20px;"><p style="text-align:right;font-size:20px;">'+this.fulldate+'</p><p style="font-size:20px;">เรียน พี่เล็ก</p><p style="font-size:20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผมขอเบิกค่าใช้จ่าย ดังนี้ </p>\n<span style="background-color: rgb(255, 255, 0);font-size:20px;">ขอเบิกค่าไหว้ศาลพระภูมิ  เจ้าที่ทุกวันพระดังนี้ </span>' +
            this.Buddhist +
            "<p style='font-size:20px;'>รวมทั้งหมด " +
            parseInt(res.data.length) * 300 +
            ' บาท </p><p style="font-size:20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;จึงเรียนมาเพื่อทราบและเพื่อโปรดพิจารณา</p><p style="font-size:20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ขอแสดงความนับถือ</p><p style="font-size:20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อำนาจ&nbsp;จันทวงค์</p>';
          // console.log(this.data);
        }
      }
    });
  },
  methods: {
    saveDocx() {
      console.log(this.data);
            const htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Document</title>
      </head>
      <body>`+
        this.data
        +
      `</body>
      </html>`
            asBlob(htmlString).then(data => {
              saveAs(data, 'เบิกเงิน_'+this.fulldate+'.docx') // save as docx file
            }) // asBlob() return Promise<Blob|Buffer>
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
