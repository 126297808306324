import http from "../http-common";

class WageSlipService {
  createwageslip(data) {
    return http.post('/wageslip', data);
  }
  getwageslips(start,end,user) {
    return http.get(`/wageslip?start=`+start+'&&end='+end+'&&user='+user);
  }
  getwageslip(id) {
    return http.get(`/wageslip/` + id);
  }
  updatewageslip(id, data) {
    return http.put(`/wageslip/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/wageslip/updateno/${id}`, data);
  }
  deletewageslip(id) {
    return http.delete(`/wageslip/` + id);
  }
}

export default new WageSlipService();