<template>
  <div class="container">
    <!-- <div class="col-lg-12 table-responsive"> 
    <div style="padding-bottom: 10px;padding-top: 10px;">  
    <div class="row">  
      <div class="col-sm-12">  
        <button @click="ExportTOExcel()" class="btn btn-success">ExportTOExcel</button> </div>  
    </div>  
  </div>  
    </div>
  <table class="table table-bordered" id="tbl">  
    <thead class="thead-dark">  
      <tr>  
        <th>S.No</th>  
        <th>Team</th>  
        <th>Match</th>  
        <th>Win</th>  
        <th>Loss</th>  
        <th>Cancel</th>  
        <th>Point</th>  
      </tr>  
    </thead>  
    <tbody>  
      <tr v-for="(tm, i) in team" :key="i + 1">  
        <td>{{tm.Sno}}</td>  
        <td>{{tm.Team}}</td>  
        <td>{{tm.Match}}</td>  
        <td>{{tm.Win}}</td>  
        <td>{{tm.Loss}}</td>  
        <td>{{tm.Cancel}}</td>  
        <td>{{tm.Point}}</td>  
      </tr>  
    </tbody>  
  </table>   -->
    <div style="text-align:right" class="mt-3">
      <button
      @click="exportTableToExcel('tblData')"
        class="btn btn-success"
      >
        <i class="fa fa-file-excel-o"></i> Export
      </button></div>
      <div>
    <table class="table mt-3">
      <tr>
        <th colspan="4" style="text-align: center">
          รายงานการทำงานตั้งแต่วันที่ {{ fulldate }}
        </th>
      </tr>
      <tr>
        <th>วันที่</th>
        <th>ชื่อ</th>
        <th>งานที่ทำ</th>
        <th>หมายเหตุ</th>
        <th>
          <a>
          <button
            type="button"
            class="btn btn-success btn-md"
            data-bs-toggle="modal"
            data-bs-target="#AddUser"
          >
            <i class="fa fa-plus"></i>
          </button>
          </a>
        </th>
      </tr>
      <tbody
        is="draggable"
        :list="list"
        tag="tbody"
        @change="onUnpublishedChange"
      >
        <tr v-for="(l, i) in list" :key="i + 1">
          <td>
            {{ l.datename }}
          </td>
          <td>
            <input
            @change="updatename(l)"
              v-model="l.name"
              type="text"
              min="1"
              class="form-control form-control-sm"
            />
          </td>
          <td style="width: 300px">
            <input
            @change="updatename(l)"
              v-model="l.workId"
              type="text"
              min="1"
              class="form-control form-control-sm"
            />
          </td>
          <td>
            <input
            @change="updatename(l)"
              v-model="l.remark"
              type="text"
              min="1"
              class="form-control form-control-sm"
            />
          </td>
          <td>
            <button
              @click="deletework(l.id)"
              type="button"
              class="btn btn-danger btn-md"
            >
              <i class="fa fa-remove"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div id="dvData">
    <table
      class="table mt-5"
      id="tblData"
      ref="exportable_table"
      border="1" style="border-collapse: collapse;display: none"
    >
      <tr>
        <th colspan="4" style="text-align: center;font-size:18px">
          รายงานการทำงานตั้งแต่วันที่ {{ fulldate }}
        </th>
      </tr>
      <tr>
        <th style="font-size:14px">วันที่</th>
        <th style="font-size:14px">ชื่อ</th>
        <th style="font-size:14px;width:300px">งานที่ทำ</th>
        <th style="font-size:14px">หมายเหตุ</th>
      </tr>
      <tbody>
        <tr v-for="(l, i) in list" :key="i + 1">
          <td style="font-size:14px">
            {{ l.datename }}
          </td>
          <td style="font-size:14px">
            {{ l.name }}
          </td>
          <td style="width: 300px;font-size:14px;">{{ l.workId }}</td>
          <td style="font-size:14px">{{ l.remark }}</td>
        </tr>
      </tbody>
    </table>
   </div>
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">เพิ่มรายการทำงาน</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="username">Date</label>
                <input
                  v-model="data.date"
                  type="date"
                  min="1"
                  class="form-control form-control-sm"
                  id="username"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="save()"
            >
              Save
            </button>
            <button
              id="closedmenu"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="xlsx-js-style"></script>

<script>
// import * as XLSX from "xlsx/xlsx.mjs";
import draggable from "vuedraggable";
import WorkReportService from "../services/WorkReportService.js";
import * as XLSX from 'xlsx-js-style';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    draggable,
  },
  data() {
    return {
      jsondata: [
        {
          date: "2024-06-01",
          name: "นาย ประไพ",
          work: "รดน้ำ บ้านแปลง 25 กวาดใบไม้แปลง 25,29",
          remark: "",
        },
        { user: "", name: "", work: "", remark: "" },
      ],
      list: [],
      start: "",
      end: "",
      fulldate: "",
      date: "",
      month: "",
      year: "",
      data: {},
      team: [
    {
      Sno: 1,
      Team: 'India',
      Match: 8,
      Win: 7,
      Loss: 0,
      Cancel: 1,
      Point: '01-June-2019',
    },
    {
      Sno: 2,
      Team: 'NewZeland',
      Match: 8,
      Win: 6,
      Loss: 1,
      Cancel: 1,
      Point: '02-June-2019',
    },
    {
      Sno: '3',
      Team: 'Aus',
      Match: 8,
      Win: 6,
      Loss: 1,
      Cancel: 1,
      Point: '01-July-2019',
    },
    {
      Sno: '4',
      Team: 'England',
      Match: 8,
      Win: 5,
      Loss: 2,
      Cancel: 1,
      Point: '02-July-2019',
    },
    {
      Sno: '5',
      Team: 'S.Africa',
      Match: 8,
      Win: 4,
      Loss: 3,
      Cancel: 1,
      Point: '01-August-2019',
    },
    {
      Sno: '6',
      Team: 'Pak',
      Match: 8,
      Win: 4,
      Loss: 4,
      Cancel: 1,
      Point: '02-August-2019',
    },
    {
      Sno: '7',
      Team: 'SriLanka',
      Match: 8,
      Win: 3,
      Loss: 3,
      Cancel: 2,
      Point: '01-May-2019',
    },
    {
      Sno: '8',
      Team: 'Bdesh',
      Match: 8,
      Win: 2,
      Loss: 4,
      Cancel: 2,
      Point: '02-May-2019',
    },
  ]
    };
  },
  mounted() {
    this.date = this.$route.params.date;
    this.month = parseInt(this.$route.params.month) - 1;
    this.year = this.$route.params.year;
    this.date = ("0" + this.date).slice(-2);
    this.month = ("0" + this.month).slice(-2);

    var date = new Date(this.year, this.month, this.date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log(this.year,this.month,this.date);
    if (date.getDate() <= 15) {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-01";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-15";

      this.fulldate += "1 - 15 ";
    } else {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-16";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2);
      // var l = ((lastDay.getDate()).slice(-2))
      // console.log(l);
      this.fulldate += "16 - " + lastDay.getDate() + " ";
    }
    console.log(this.start);
    console.log(this.end);

    const result = date.toLocaleDateString("th-TH", {
      month: "long",
    });
    this.fulldate += result + " " + (parseInt(date.getFullYear()) + 543);
    // console.log(result);
    WorkReportService.getworkreports(this.start, this.end).then((res) => {
      if (res.data.length == 0) {
        if (date.getDate() <= 15) {
          for (let i = 0; i < 15; i++) {
            var data = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
              name: "นาย ประไพ",
              workId: "",
              remark: "",
              status: 1,
              createdBy: 1,
              updatedBy: 1,
            };
            WorkReportService.createworkreport(data).then(() => {
              this.getlist();
            });
          }
        } else {
          for (let i = 15; i < parseInt(lastDay.getDate()); i++) {
            var datalast = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
              name: "นาย ประไพ",
              workId: "",
              remark: "",
              status: 1,
              createdBy: 1,
              updatedBy: 1,
            };
            WorkReportService.createworkreport(datalast).then(() => {
              this.getlist();
            });
          }
        }
      } else {
        this.getlist();
      }
    });
  },
  methods: {
    ExportTOExcel() {
      var elt = document.getElementById('tbl');

    const ws = XLSX.utils.table_to_sheet(
      elt
    );
    for (var i in ws) {
      console.log(ws[i]);
      if (typeof ws[i] != 'object') continue;
      let cell = XLSX.utils.decode_cell(i);

      ws[i].s = {
        // styling for all cells
        font: {
          name: 'cordia new',
          sz:16
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };

      if (cell.r == 0) {
        // first row
        ws[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }

      // if (cell.r % 2) {
      //   // every other row
      //   ws[i].s.fill = {
      //     // background color
      //     patternType: 'solid',
      //     fgColor: { rgb: 'b2b2b2' },
      //     bgColor: { rgb: 'b2b2b2' },
      //   };
      // }
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ScoreSheet.xlsx');
  },
    exportAsExcel () {
        const format = 'xlsx'
        const exportSelectedOnly = false
        const filename = 'test'
        this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    },
    exportTableToExcel() {
      const table = document.querySelector('#dvData table');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([]);
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:D2"),
];
  const rows = table.rows;
  // console.log(rows);
  for (let i = 0; i < rows.length; i++) {
    const rowData = [];
    const cells = rows[i].cells;
    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      // attention here
      const nestedTable = cell.querySelector('table');
      if (nestedTable) {
        const nestedRows = nestedTable.rows;
        for (let k = 0; k < nestedRows.length; k++) {
          const nestedCells = nestedRows[k].cells;
          const nestedRowData = [];
          for (let l = 0; l < nestedCells.length; l++) {
            const nestedCell = nestedCells[l];
            nestedRowData.push(nestedCell.innerText);
          }
          rowData.push(...nestedRowData);
        }
      } else {
        // console.log(1);
        rowData.push(cell.innerText);
      }
    } 
    if (rowData.length == 1) {
          for (let i = 0; i < 3; i++) {
            rowData.push('')
          }
        }
    XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    
  }
  for (var i in worksheet) {
    // console.log(i);
    if (typeof worksheet[i] != 'object') continue;
    let cell = XLSX.utils.decode_cell(i);
// console.log(cell.c);
    worksheet[i].s = {
  // styling for all cells
  font: {
    name: 'cordia new',
    sz:16
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '1', // any truthy value here
  },
  border: {
    right: {
      style: 'thin',
      color: '000000',
    },
    left: {
      style: 'thin',
      color: '000000',
    },
    top: {
      style: 'thin',
      color: '000000',
    },
    bottom: {
      style: 'thin',
      color: '000000',
    },
  }
    }
    // console.log(cell);
    if (cell.c == 0 && cell.r > 2) {
        // first row
        worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }
    if (cell.c == 1 && cell.r > 2) {
        // first row
        worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }

      if (cell.r == 0) {
        // first row
        worksheet[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
      if (i == 'A2' ) {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '18',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
if (i == 'A3' ||i == 'B3'||i == 'C3'||i == 'D3' ) {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
      // if (cell.r % 2) {
      //   // every other row
      //   worksheet[i].s.fill = {
      //     // background color
      //     patternType: 'solid',
      //     fgColor: { rgb: 'b2b2b2' },
      //     bgColor: { rgb: 'b2b2b2' },
      //   };
      // }

  }
  var wscols = [
    {wch:15},
    {wch:20},
    {wch:40},
    {wch:15}
];

worksheet['!cols'] = wscols;
  // console.log(worksheet["A3"]);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  // console.log(workbook);
  var filename = 'รายงานการทำงาน_'+this.fulldate+ ".xlsx"

  const downloadLink = document.createElement('a');
  downloadLink.href = `data:application/octet-stream;base64,${btoa(excelFile)}`;
  downloadLink.download = filename;
  downloadLink.click();

    },
    
    updatename(event){
var data = {
          name: event.name,
          workId: event.workId,
          remark: event.remark,
          createdBy: 1,
          updatedBy: 1,
        };
        // console.log(event);
        WorkReportService.updateworkreport(event.id,data).then(() => {
          this.getlist();
        });
    },
    save() {
      if (this.data.date == null || this.data.date == "") {
        alert("กรุณาเลือกวันที่");
      } else {
        var data = {
          date: this.data.date,
          no: parseInt(this.list.length) + 1,
          name: "นาย ประไพ",
          workId: "",
          remark: "",
          status: 1,
          createdBy: 1,
          updatedBy: 1,
        };
        WorkReportService.createworkreport(data).then(() => {
          document.getElementById("closedmenu").click();
this.data = {}
          this.getlist();
        });
      }
    },
    onUnpublishedChange() {
      for (let l = 0; l < this.list.length; l++) {
        // console.log(l+1);
        var list = {
          no: l + 1,
        };
        WorkReportService.updateno(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getlist();
          }
        });
      }
    },
    deletework(id) {
      WorkReportService.deleteworkreport(id).then(() => {
        this.getlist();
      });
    },
    getlist() {
      WorkReportService.getworkreports(this.start, this.end).then((res) => {
        // console.log(res.data);
        for (let r = 0; r < res.data.length; r++) {
          var dt = new Date(res.data[r].date);
          var result = dt.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          res.data[r].datename = result;
          if (res.data.length == r + 1) {
            this.list = res.data;
          }
        }
      });
    },
    savework(ev) {
      console.log(ev);
    },
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheets" });
      wb["Sheets"]["!cols"] = [{ wpx: 70 }, { wpx: 121 }];
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn || (this.name + "." || "SheetJSTableExport.") + (type || "xlsx")
          );
    },
    exportAsCsv() {
      const format = "csv";
      const exportSelectedOnly = true;
      const filename = "test";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* th{
  border: 1px solid black !important;
}
td{
  border: 1px solid black !important;
} */
table,
th,
td {
  border: 1px solid #666;
  border-collapse: collapse;
}
.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}
.systable tr.select td {
  background-color: darkgrey !important;
}
</style>
