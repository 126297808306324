<template>
  <div class="container">
    <div style="text-align:right" class="mt-3">
      <button
      @click="exportTableToExcel('tblData')"
        class="btn btn-success"
      >
        <i class="fa fa-file-excel-o"></i> Export
      </button></div>
    <table class="table mt-3">
      <tr>
        <th :colspan="parseInt(datelist.length)+6" style="text-align: center">
          บริษัท พนาธาร จำกัด
        </th>
      </tr>
      <tr>
        <th :colspan="parseInt(datelist.length)+6">
          โครงการชลธาร เขาใหญ่
        </th>
      </tr>
      <tr>
        <th :colspan="parseInt(datelist.length)+6">ค่าแรงคนงานทำงานก่อสร้างงวดประจำวันที่ {{ fulldate }}</th>
      </tr>
      <tr>
            <th rowspan="2"></th>
            <th style="vertical-align: middle;text-align: center;width:200px" rowspan="2">รายชื่อ</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2">ค่าแรงวันละ</th>
            <th style="text-align: center" :colspan="datelist.length">วันที่</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2">จำนวน</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2">เป็นเงิน</th>
            <th style="vertical-align: middle;text-align: center;" rowspan="2">ลายเซ็น</th>
        </tr>
        <tr>
            <td v-for="d in datelist" :key="d.id">{{d.datename}}</td>
        </tr>
        <tr>
            <td>1</td>
            <td>นางทัศนีย์ จันทวงค์</td>
            <td>
              <input
              v-model="bath"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
            <td v-for="v in datelist" :key="v.id"> 
              <input type="checkbox" @change="updatename(v)" style="margin-left: -5px;" 
              class="form-check-input" id="flexCheckChecked" value="true" v-model="v.value">
          </td>
          <td>{{count}}</td>
          <td>{{count*bath}}</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td>รวม</td>
          <td>{{count*bath}}</td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td>วันจันทร์</td>
            <td><input
              v-model="detail.mon"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันอังคาร</td>
            <td><input
              v-model="detail.tue"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันพุธ</td>
            <td><input
              v-model="detail.wed"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันพฤหัสบดี</td>
            <td><input
              v-model="detail.thu"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันศุกร์</td>
            <td><input
              v-model="detail.fri"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันเสาร์</td>
            <td><input
              v-model="detail.sat"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
        <tr>
            <td></td>
            <td>วันอาทิตย์</td>
            <td><input
              v-model="detail.sun"
              type="text"
              min="1"
              class="form-control form-control-sm"
            /></td>
        </tr>
    </table>
    <div id="dvData">
    <table class="table mt-3"
    id="tblData"
      ref="exportable_table"
      border="1" style="border-collapse: collapse;display: none">
      <tr>
        <th :colspan="parseInt(datelist.length)+6" style="text-align: center;font-size:18px">
          บริษัท พนาธาร จำกัด
        </th>
      </tr>
      <tr>
        <th :colspan="parseInt(datelist.length)+6" style="font-size:16px">
          โครงการชลธาร เขาใหญ่
        </th>
      </tr>
      <tr>
        <th :colspan="parseInt(datelist.length)+6" style="font-size:16px">ค่าแรงคนงานทำงานก่อสร้างงวดประจำวันที่ {{ fulldate }}</th>
      </tr>
      <tr>
            <th rowspan="2"></th>
            <th style="vertical-align: middle;text-align: center;width:200px;font-size:14px" rowspan="2">รายชื่อ</th>
            <th style="vertical-align: middle;text-align: center;font-size:14px" rowspan="2">ค่าแรงวันละ</th>
            <th style="text-align: center;font-size:14px" :colspan="datelist.length">วันที่</th>
            <th style="vertical-align: middle;text-align: center;font-size:14px" rowspan="2">จำนวน</th>
            <th style="vertical-align: middle;text-align: center;font-size:14px" rowspan="2">เป็นเงิน</th>
            <th style="vertical-align: middle;text-align: center;font-size:14px" rowspan="2">ลายเซ็น</th>
        </tr>
        <tr>
            <td v-for="d in datelist" :key="d.id" style="font-size:14px">{{d.datename}}</td>
        </tr>
        <tr>
            <td style="font-size:14px">1</td>
            <td style="font-size:14px">นางทัศนีย์ จันทวงค์</td>
            <td style="font-size:14px">{{bath}}</td>
            <td style="font-size:14px" v-for="v in datelist" :key="v.id"> 
              {{v.valuename}}
          </td>
          <td style="font-size:14px">{{count}}</td>
          <td style="font-size:14px">{{count*bath}}</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="font-size:14px" v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="font-size:14px" v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td style="font-size:14px">รวม</td>
          <td style="font-size:14px">{{count*bath}}</td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="font-size:14px" v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="font-size:14px" v-for="v in datelist" :key="v.id"> 
              
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันจันทร์   {{detail.mon}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันอังคาร  {{detail.tue}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันพุธ  {{detail.wed}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันพฤหัสบดี  {{detail.thu}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันศุกร์  {{detail.fri}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันเสาร์  {{detail.sat}}</td>
        </tr>
        <tr>
            <td></td>
            <td style="font-size:14px">วันอาทิตย์  {{detail.sun}}</td>
        </tr>
    </table>
    </div>
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">เพิ่มรายการทำงาน</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="username">Date</label>
                <input
                  v-model="data.date"
                  type="date"
                  min="1"
                  class="form-control form-control-sm"
                  id="username"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="save()"
            >
              Save
            </button>
            <button
              id="closedmenu"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx-js-style';
import WorkReportService from "../services/WorkReportService.js";
import WageSlipService from "../services/WageSlipService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
      jsondata: [
        {
          date: "2024-06-01",
          name: "นาย ประไพ",
          work: "รดน้ำ บ้านแปลง 25 กวาดใบไม้แปลง 25,29",
          remark: "",
        },
        { user: "", name: "", work: "", remark: "" },
      ],
      list: [],
      start: "",
      end: "",
      fulldate: "",
      date: "",
      month: "",
      year: "",
      data: {},
      datelist:[],
      count:0,
      bath:320,
      detail:{mon:'ทำบ้านแปลง 25',
    tue:'ทำคอนโด ห้อง 211',
  wed:'ทำคอนโด ห้อง 403',
thu:'ทำบ้าน แปลง 25',
fri:'ทำคอนโด ห้อง 211',
sat:'คอนโด ห้อง 403',
sun:'ทำคอนโด ห้อง 211'}
    };
  },
  mounted() {
    this.datelist = []
    this.date = this.$route.params.date;
    this.month = parseInt(this.$route.params.month) - 1;
    this.year = this.$route.params.year;
    this.date = ("0" + this.date).slice(-2);
    this.month = ("0" + this.month).slice(-2);

    var date = new Date(this.year, this.month, this.date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log(this.year,this.month,this.date);
    this.datelist = []
    if (date.getDate() <= 15) {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-01";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-15";

      this.fulldate += "1 - 15 ";
    } else {
      this.start =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-16";
      this.end =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2);
      // var l = ((lastDay.getDate()).slice(-2))
      // console.log(l);
      this.fulldate += "16 - " + lastDay.getDate() + " ";
    }
    console.log(this.start);
    console.log(this.end);

    const result = date.toLocaleDateString("th-TH", {
      month: "long",
    });
    this.fulldate += result + " " + (parseInt(date.getFullYear()) + 543);
    // console.log(result);
    WageSlipService.getwageslips(this.start, this.end,2).then((res) => {
      this.datelist = []
      if (res.data.length == 0) {
        if (date.getDate() <= 15) {
          for (let i = 0; i < 15; i++) {
            var data = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
              value: "1",
              user:2
            };
            WageSlipService.createwageslip(data).then(() => {
              this.getlist();
            });
          }
        } else {
          for (let i = 15; i < parseInt(lastDay.getDate()); i++) {
            var datalast = {
              date:
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + (i + 1)).slice(-2),
              no: i + 1,
             value:'1',
             user:2
            };
            WageSlipService.createwageslip(datalast).then(() => {
              this.getlist();
            });
          }
        }
      } else {
        this.getlist();
      }
    });
  },
  methods: {
    exportTableToExcel() {
      const table = document.querySelector('#dvData table');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([]);
  
  if (this.datelist.length == 13) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:S2"),
    XLSX.utils.decode_range("A5:A6"),
  XLSX.utils.decode_range("B5:B6"),
  XLSX.utils.decode_range("C5:C6"),
  XLSX.utils.decode_range("A3:S3"),
  XLSX.utils.decode_range("A4:S4"),
   XLSX.utils.decode_range("D5:P5"),
   XLSX.utils.decode_range("R5:R6"),
   XLSX.utils.decode_range("Q5:Q6"),
   XLSX.utils.decode_range("S5:S6"),
   XLSX.utils.decode_range("B12:C12"),
   XLSX.utils.decode_range("B13:C13"),
   XLSX.utils.decode_range("B14:C14"),
   XLSX.utils.decode_range("B15:C15"),
   XLSX.utils.decode_range("B16:C16"),
   XLSX.utils.decode_range("B17:C17"),
   XLSX.utils.decode_range("B18:C18"),

]
} else  if (this.datelist.length == 14) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:T2"),
    XLSX.utils.decode_range("A5:A6"),
  XLSX.utils.decode_range("B5:B6"),
  XLSX.utils.decode_range("C5:C6"),
  XLSX.utils.decode_range("A3:T3"),
  XLSX.utils.decode_range("A4:T4"),
   XLSX.utils.decode_range("D5:Q5"),
   XLSX.utils.decode_range("R5:R6"),
   XLSX.utils.decode_range("T5:T6"),
   XLSX.utils.decode_range("S5:S6"),
   XLSX.utils.decode_range("B12:C12"),
   XLSX.utils.decode_range("B13:C13"),
   XLSX.utils.decode_range("B14:C14"),
   XLSX.utils.decode_range("B15:C15"),
   XLSX.utils.decode_range("B16:C16"),
   XLSX.utils.decode_range("B17:C17"),
   XLSX.utils.decode_range("B18:C18"),

]
} else if (this.datelist.length == 15) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:U2"),
    XLSX.utils.decode_range("A5:A6"),
  XLSX.utils.decode_range("B5:B6"),
  XLSX.utils.decode_range("C5:C6"),
  XLSX.utils.decode_range("A3:U3"),
  XLSX.utils.decode_range("A4:U4"),
   XLSX.utils.decode_range("D5:R5"),
   XLSX.utils.decode_range("S5:S6"),
   XLSX.utils.decode_range("T5:T6"),
   XLSX.utils.decode_range("U5:U6"),
   XLSX.utils.decode_range("B12:C12"),
   XLSX.utils.decode_range("B13:C13"),
   XLSX.utils.decode_range("B14:C14"),
   XLSX.utils.decode_range("B15:C15"),
   XLSX.utils.decode_range("B16:C16"),
   XLSX.utils.decode_range("B17:C17"),
   XLSX.utils.decode_range("B18:C18"),

]
}
else if (this.datelist.length == 16) {
  worksheet["!merges"] = [
  XLSX.utils.decode_range("A2:V2"),
    XLSX.utils.decode_range("A5:A6"),
  XLSX.utils.decode_range("B5:B6"),
  XLSX.utils.decode_range("C5:C6"),
  XLSX.utils.decode_range("A3:V3"),
  XLSX.utils.decode_range("A4:V4"),
   XLSX.utils.decode_range("D5:S5"),
   XLSX.utils.decode_range("T5:T6"),
   XLSX.utils.decode_range("U5:U6"),
   XLSX.utils.decode_range("V5:V6"),
   XLSX.utils.decode_range("B12:C12"),
   XLSX.utils.decode_range("B13:C13"),
   XLSX.utils.decode_range("B14:C14"),
   XLSX.utils.decode_range("B15:C15"),
   XLSX.utils.decode_range("B16:C16"),
   XLSX.utils.decode_range("B17:C17"),
   XLSX.utils.decode_range("B18:C18"),
]
}
  const rows = table.rows;
  // console.log(rows);
  for (let i = 0; i < rows.length; i++) {
    var rowData = [];
    const cells = rows[i].cells;
    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      // attention here
      const nestedTable = cell.querySelector('table');
      if (nestedTable) {
        const nestedRows = nestedTable.rows;
        for (let k = 0; k < nestedRows.length; k++) {
          const nestedCells = nestedRows[k].cells;
          const nestedRowData = [];
          for (let l = 0; l < nestedCells.length; l++) {
            const nestedCell = nestedCells[l];
            nestedRowData.push(nestedCell.innerText);
          }
          rowData.push(...nestedRowData);
        }
      } else {
        // console.log(1);
        rowData.push(cell.innerText);
      }
    } 
    var mergename = []
    if (this.datelist.length ==14) {
      if (rowData.length == 1) {
      var counts = parseInt(this.datelist.length)+5
          for (let i = 0; i < counts; i++) {
            rowData.push('')
          }
        }
      if (rowData.length == this.datelist.length) {
          for (let i = 0; i < 3; i++) {
            mergename.push('')
            if (i==2) {
              for (let r = 0; r < rowData.length; r++) {
                mergename.push(rowData[r])
              }
            }
          }
          mergename.push('')
          mergename.push('')
          mergename.push('')
          rowData = mergename
        }
        if (rowData.length == 7) {
          var originalArray = rowData;
          for (let ao = 0; ao < (this.datelist.length-1); ao++) {
originalArray = this.insertItemAtIndex(originalArray, ao+4, '');
// console.log(originalArray);            
          }
          rowData = originalArray
        }
      if (rowData.length == 19) {
      rowData.push('')
    }
    if (rowData.length == 2) {
      rowData.push('')
    }
    }else{
    if (rowData.length == this.datelist.length) {
          for (let i = 0; i < 3; i++) {
            mergename.push('')
            if (i==2) {
              for (let r = 0; r < rowData.length; r++) {
                mergename.push(rowData[r])
              }
            }
          }
          mergename.push('')
          mergename.push('')
          mergename.push('')
          rowData = mergename
        }
    if (rowData.length == 1) {
       counts = parseInt(this.datelist.length)+5
          for (let i = 0; i < counts; i++) {
            rowData.push('')
          }
        }
        if (rowData.length == 7) {
           originalArray = rowData;
          for (let ao = 0; ao < (this.datelist.length-1); ao++) {
originalArray = this.insertItemAtIndex(originalArray, ao+4, '');
// console.log(originalArray);            
          }
          rowData = originalArray
        }
        if (rowData.length == 20) {
      rowData.push('')
    }
     if (this.datelist.length == 16) {
       if (rowData.length == 21) {
      rowData.push('')
    }
    
  }
  if (this.datelist.length == 13) {
       if (rowData.length == 18) {
      rowData.push('')
    }
    
  }
  if (rowData.length == 2) {
      rowData.push('')
    }
  }
  console.log(rowData);
    XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    
  }
  for (var i in worksheet) {
    // console.log(i);
    if (typeof worksheet[i] != 'object') continue;
    let cell = XLSX.utils.decode_cell(i);
// console.log(cell.c);
    worksheet[i].s = {
  // styling for all cells
  font: {
    name: 'cordia new',
    sz:16
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '1', // any truthy value here
  },
  border: {
    right: {
      style: 'thin',
      color: '000000',
    },
    left: {
      style: 'thin',
      color: '000000',
    },
    top: {
      style: 'thin',
      color: '000000',
    },
    bottom: {
      style: 'thin',
      color: '000000',
    },
  }
    }
    // console.log(cell);
    if (i == 'A2' || i == 'A3') {
      worksheet['!rows'] = [
            {'hpt' : 30},// height for row 1
            {'hpt' : 30},
          {'hpt' : 30},
        {'hpt' : 20}, ]; //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '18',
  }
    }
    if (i == 'B12' || i == 'B13' || i == 'B14' || i == 'B15' || i == 'B16' || i == 'B17' || i == 'B18') {
      worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }
    if (cell.c == 0 && cell.r == 3) {
      worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }
    if (cell.c == 0 && cell.r > 2) {
        // first row
        worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }

      if (cell.r == 0) {
        // first row
        worksheet[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
      if (i == 'A2' || i == 'A3') {
      worksheet['!rows'] = [
            {'hpt' : 30},// height for row 1
            {'hpt' : 30},
          {'hpt' : 30},
        {'hpt' : 20}, ]; //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '18',
    bold:true
  }
    }
    if (i == 'B12' || i == 'B13' || i == 'B14' || i == 'B15' || i == 'B16' || i == 'B17' || i == 'B18') {
      worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
  }
    }
    if (i == 'B5'||i == 'C5'||i == 'D5' ||i == 'S5' ||i == 'T5' ||i == 'U5' ||i == 'R5' ||i == 'Q5' ||i == 'V5' ) {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
if (i == 'A4') {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
    wrapText: '0', // any truthy value here
  }
}
if (i == 'B7' || i == 'C7') {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'left',
    horizontal: 'left',
    wrapText: '0', // any truthy value here
  }
}
  }
  var wscols = [
    {wch:10},
    {wch:20},
    {wch:15},
];

worksheet['!cols'] = wscols;
  // console.log(worksheet["A3"]);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  // console.log(workbook);
  var filename = 'ใบค่าแรง คนงานทำห้องที่ไม่ขาย_'+this.fulldate+ ".xlsx"
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:application/octet-stream;base64,${btoa(excelFile)}`;
  downloadLink.download = filename;
  downloadLink.click();

    },
    // exportTableToExcel(tableID, filename = "") {
    //   var downloadLink;
    //   var dataType = "application/vnd.ms-excel";
    //   var tableSelect = document.getElementById(tableID);
    //   var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
    //   filename = 'ใบค่าแรง คนงานทำห้องที่ไม่ขาย_'+this.fulldate+ ".xls"
    //   // Create download link element
    //   downloadLink = document.createElement("a");

    //   document.body.appendChild(downloadLink);

    //   if (navigator.msSaveOrOpenBlob) {
    //     var blob = new Blob(["\ufeff", tableHTML], {
    //       type: dataType,
    //     });
    //     navigator.msSaveOrOpenBlob(blob, filename);
    //   } else {
    //     // Create a link to the file
    //     downloadLink.href = "data:" + dataType + ", " + tableHTML;

    //     // Setting the file name
    //     downloadLink.download = filename;

    //     //triggering the function
    //     downloadLink.click();
    //   }
    // },
    insertItemAtIndex(arr, index, item) {
  if (index < 0 || index > arr.length) {
    // Index out of bounds, 
    // return the original array
    return arr;
  }

  return arr.slice(0, index)
    .concat(item, arr.slice(index));
},
    updatename(event){
      var value = event.value
      console.log(event);
      if (event.value == 0) {
        value = '0'
      }else{
        value = '1'
      }
var data = {
          value: value,
        };
        // console.log(event);
        WageSlipService.updatewageslip(event.id,data).then(() => {
          this.getlist();
        });
    },
    save() {
      if (this.data.date == null || this.data.date == "") {
        alert("กรุณาเลือกวันที่");
      } else {
        var data = {
          date: this.data.date,
          no: parseInt(this.list.length) + 1,
          name: "นาย ประไพ",
          workId: "",
          remark: "",
          status: 1,
          createdBy: 1,
          updatedBy: 1,
        };
        WorkReportService.createworkreport(data).then(() => {
          document.getElementById("closedmenu").click();
this.data = {}
          this.getlist();
        });
      }
    },
    onUnpublishedChange() {
      for (let l = 0; l < this.list.length; l++) {
        // console.log(l+1);
        var list = {
          no: l + 1,
        };
        WorkReportService.updateno(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getlist();
          }
        });
      }
    },
    deletework(id) {
      WorkReportService.deleteworkreport(id).then(() => {
        this.getlist();
      });
    },
    getlist() {
      this.datelist = []
      this.count = 0
      WageSlipService.getwageslips(this.start, this.end,2).then((res) => {
        // console.log(res.data);
        this.datelist = []
        this.count = 0
        for (let r = 0; r < res.data.length; r++) {
          var dt = new Date(res.data[r].date);
          // var result = dt.toLocaleDateString("th-TH", {
          //   year: "numeric",
          //   month: "short",
          //   day: "numeric",
          // });
          if (res.data[r].value == 1) {
            this.count = this.count+1
            res.data[r].value = true
            res.data[r].valuename = 1
          }else{
            res.data[r].value = false
            res.data[r].valuename = 0
          }
          res.data[r].datename = dt.getDate();
          this.datelist.push(res.data[r])
          if (res.data.length == r + 1) {
            console.log(this.datelist);
          }
        }
      });
    },
    savework(ev) {
      console.log(ev);
    },
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheets" });
      wb["Sheets"]["!cols"] = [{ wpx: 70 }, { wpx: 121 }];
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn || (this.name + "." || "SheetJSTableExport.") + (type || "xlsx")
          );
    },
    exportAsCsv() {
      const format = "csv";
      const exportSelectedOnly = true;
      const filename = "test";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* th{
  border: 1px solid black !important;
}
td{
  border: 1px solid black !important;
} */
table,
th,
td {
  border: 1px solid #666;
  border-collapse: collapse;
}
.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}
</style>
