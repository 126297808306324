import http from "../http-common";

class CheckInService {
  createcheckin(data) {
    return http.post('/checkin', data);
  }
  getcheckins(start,end) {
    return http.get(`/checkin?start=`+start+'&&end='+end);
  }
  getlisthome(start,end) {
    return http.get(`/name?start=`+start+'&&end='+end);
  }
  findBuddhist(start,end) {
    return http.get(`/checkin/findBuddhist?start=`+start+'&&end='+end);
  }
  createname(data) {
    return http.post('/name', data);
  }
  updatename(id, data) {
    return http.put(`/name/${id}`, data);
  }
  getname(id) {
    return http.get(`/name/` + id);
  }
  getcheckin(id) {
    return http.get(`/checkin/` + id);
  }
  updatecheckin(id, data) {
    return http.put(`/checkin/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/checkin/updateno/${id}`, data);
  }
  deletecheckin(id) {
    return http.delete(`/checkin/` + id);
  }
}

export default new CheckInService();