import http from "../http-common";

class WorkReportService {
  createworkreport(data) {
    return http.post('/workreport', data);
  }
  getworkreports(start,end) {
    return http.get(`/workreport?start=`+start+'&&end='+end);
  }
  getworkreport(id) {
    return http.get(`/workreport/` + id);
  }
  updateworkreport(id, data) {
    return http.put(`/workreport/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/workreport/updateno/${id}`, data);
  }
  deleteworkreport(id) {
    return http.delete(`/workreport/` + id);
  }
}

export default new WorkReportService();